<script>
import { sitioBus } from "@/main";
import listingBox from "@/components/listing-box.vue";
import { wpService } from "@/services/wp";
export default {
  components: {
    listingBox,
  },

  props: {
    entry: {
      default: null,
      type: Object,
    },
  },

  computed: {
    valid_in_search() {
      return this.expiry_left > 0;
    },
    valid_in_featured() {
      return false;
    },
    ltg_display_end_date() {
      let ltg_display_end_date = this.entry.ltg_display_end_date || 0;
      ltg_display_end_date = ltg_display_end_date * 1000;
      return ltg_display_end_date;
    },
    expiry_left() {
      return this.ltg_display_end_date - Date.now();
    },
    canRenew() {
      let oneday = 86400000;
      return this.expiry_left < oneday * 2;
    },
  },

  methods: {
    dialogInsufficientFunds() {
      // this.$buefy.dialog.confirm({
      //   title: "Fondos insuficientes",
      //   message:
      //     "Esta accion requie",
      //   confirmText: "Comprar créditos",
      //   // type: "is-danger",
      //   hasIcon: true,
      //   onConfirm: () => this.$router.push({ name: "account-credits" })
      // });
    },
    renewPrompt() {
      wpService.listings
        .renewSingle(this.entry.ltg_id)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    patchAutoRenew() {
      let loading = this.$buefy.loading.open({ container: null });
      let data = { ltg_display_autorenew: !this.entry.ltg_display_autorenew };
      wpService.listings
        .patchSingle(this.entry.ltg_id, data)
        .then(() => {
          this.$buefy.toast.open({
            message: this.entry.ltg_display_autorenew
              ? "Renovación automática activada"
              : "Renovación automática desactivada",
            type: "is-success",
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    increase_expiry() {
      // ¿Desea agregar 30 días de visibilidad en resultados de busquedas?
      // ¿Desea agregar 30 días a la vigencia de tu anuncio en los resultados de busqueda?
      // Se extenderá 30 días la visibilidad de tu anuncio en los resultados de busqueda
      // ¿Desea agregar 30 días de visibilidad en resultados de busquedas?
      // ¿Desea extender por 30 días la visibilidad del anuncio en resultados de busquedas?
      // La visibilidad de este anuncio en los resultados de busquedas será extendida por 30 días.
      let message = this.valid_in_search
        ? "La visibilidad de este anuncio en los resultados de busquedas será extendida por 30 días."
        : "30 días de visibilidad en resultados de busqueda.";
      // : "Mostrar anuncio en resultados de busqueda por 30 días.";
      this.$buefy.dialog.confirm({
        // message: "¿Seguro que desea eliminar este anuncio?",
        // message: `<div>Se debitara <b>1 crédito</b> de tu balance</div>`,
        title: "Visibilidad en busquedas",
        message: `<div class="content">
                    <div>${message}</div>
                    <div>Costo: <b>1 crédito</b></div>
                  </div>`,
        // type: "is-warning",
        confirmText: "Continuar",
        cancelText: "Cancelar",
        onConfirm: () => {
          // const loader = this.$buefy.loading.open();
          // let data = {
          //   ltg_id: this.ltg_id
          // };
          // wpService.listings
          //   .promotion(data)
          //   .catch(err => {
          //     console.log(err);
          //     if (
          //       err.hasOwnProperty("code") &&
          //       err.code === "insufficient_funds"
          //     ) {
          //       this.dialogInsufficientFunds();
          //     }
          //   })
          //   .finally(() => {
          //     loader.close();
          //   });
        },
      });
    },
    deleteConfirm() {
      this.$buefy.dialog.confirm({
        message: "¿Seguro que desea eliminar este anuncio?",
        type: "is-warning",
        confirmText: "Si",
        cancelText: "No",
        onConfirm: () => {
          let loading = this.$buefy.loading.open();
          this.$router.push({ name: "account-listings" });
          wpService.listings.deleteSingle(this.entry.ltg_id).then(() => {
            loading.close();
            sitioBus.$emit("refetch_account_listings");
            this.$buefy.toast.open({
              type: "is-success",
              message: "¡Anuncio eliminado!",
            });
          });
        },
      });
    },
  },
};
</script>

<template>
  <listingBox title="Estado del anuncio">
    <div class="databox">
      <div class="da__label">Creado:</div>
      <div class="da__value">
        {{ entry.ltg_date_created | dateUnixLiteral }}
      </div>
    </div>
    <div class="databox databox--expiry" :class="{ good: valid_in_search }">
      <div class="da__label" v-if="valid_in_search">Expira:</div>
      <div class="da__label" v-else>Expiró:</div>
      <div class="da__value">
        {{ entry.ltg_display_end_date | dateUnixLiteral }}
      </div>
    </div>
    <div v-if="valid_in_search" class="banner--renew">
      <div>
        <div class="banner__title">Prolonga tu anuncio</div>
        <p class="banner__copy">30 días adicionales por 1 crédito</p>
      </div>
      <button class="button is-small is-primary" @click="renewPrompt">
        <b>Prolongar</b>
      </button>
    </div>
    <div v-else class="banner--renew">
      <div>
        <div class="banner__title">Reactiva este anuncio</div>
        <p class="banner__copy">30 días de visibilidad por 1 crédito</p>
      </div>
      <button class="button is-small is-primary" @click="renewPrompt">
        <b>Renovar</b>
      </button>
    </div>
    <hr />
    <div class="block renewal">
      <div class="header">
        <h4 class="title">Renovación Automática</h4>
        <b-switch
          :value="entry.ltg_display_autorenew"
          @click.native.prevent="patchAutoRenew"
        />
      </div>
      <div class="content">
        Su cuenta debe disponer de créditos suficientes para que el anuncio
        pueda ser renovado de forma automática en la fecha de expiración, de lo
        contrario el anuncio será eliminado.
      </div>
    </div>
    <!-- <hr /> -->
    <!-- <div class="block premium">
      <div class="header">
        <h4 class="title">Modalidad Premium</h4>
        <b-button type="is-primary" size="is-small" rounded>Activar</b-button>
      </div>
      <div class="content">
        <ul>
          <li>Se incluye en el área de destacados de la portada.</li>
          <li>
            Se incluye como sugerencia en fichas de anuncios con características
            similares.
          </li>
          <li>Remueve la publicidad de terceros.</li>
        </ul>
      </div>
    </div>
    <hr /> -->
    <!-- <hr />
    ¿No necesitas más este anuncio?
    <b-button type="is-danger" size="is-small" rounded @click="deleteConfirm">
      Eliminar anuncio
    </b-button> -->
  </listingBox>
</template>

<style lang="scss" scoped>
.databox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9375rem;
  margin-bottom: 0.5rem;
  .da__label {
    font-weight: 500;
    color: #000;
  }
  .da__value {
    color: #000;
    font-weight: bold;
  }
}
.databox--expiry:not(.good) * {
  color: $danger;
}
.block {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .title {
      font-size: 1rem;
      margin: 0;
    }
  }
  .content {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}
.banner--renew {
  border-radius: 0.25rem;
  margin: 1rem 0;
  background-color: #5461ff;
  background: linear-gradient(160deg, rgba(84, 97, 255, 1) 0%, #5d2baf 100%);
  color: #fff;
  padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .banner__title {
    font-weight: 800;
    font-size: 1rem;
  }
  .banner__copy {
    font-size: 0.875rem;
  }
  .button {
    margin: 0.25rem 0;
    padding: 1.5em 1em;
  }
}
.renewal /deep/ .switch .control-label {
  display: none;
}
.option {
  margin-bottom: 1rem;
  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid #ecf0f4;
  }
}
</style>
